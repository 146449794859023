@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.cart-mobile__items {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;

  @include mainContentStyles;

  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }
}

.stepper {
  margin-left: 30px;

  @include min-428-break {
    margin-left: 36px;
  }
}

.clear {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;

  @include min-428-break {
    min-height: 40px;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 6px;

    @include min-428-break {
      gap: 8px;
    }

    .counter {
      display: flex;
      align-items: center;
      gap: 8px;

      @include min-428-break {
        gap: 12px;
      }

      .skeleton {
        width: 18px;
        height: 18px;

        @include min-428-break {
          width: 24px;
          height: 24px;
        }
      }
    }

    .title {
      color: var(--cst-top-up-cash-out-method-title);
      @include typography-base;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-m;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
    flex-direction: row;
  }
}
