@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.cart-mobile__purchase-checkout {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 12px;

  @include mainContentStyles;

  padding: 12px 14px;

  @include min-428-break {
    padding: 14px;
  }

  @include min-744-break {
    padding: 14px 28px;
  }

  .partial-payment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      @include typography-s;
      @include weight-semi-bold;
      color: var(--cst-cards-checkout-info-text);
      @include min-428-break {
        @include typography-base;
      }
    }
  }

  .total {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include min-428-break {
      gap: 14px;
    }

    .value-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      .value {
        @include typography-s;
        @include weight-semi-bold;
      }
      .old-value {
        color: #9e9d9e;
        @include typography-xs;
        @include weight-medium;
        text-decoration: line-through;
      }
    }

    .items-container {
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include min-428-break {
        gap: 14px;
      }
    }
  }

  .total__row-price {
    @include typography-s;
    @include weight-semi-bold;

    &-pos {
      color: var(--cst-cards-checkout-info-discount);
    }

    &-neg {
      color: var(--cst-cards-checkout-info-price-increase);
    }

    &-neutral {
      color: var(--cst-cards-checkout-info-detatils);
    }

    &-sum {
      @include typography-s;
      @include weight-bold;
      color: #303030;
    }
  }
}

.properties {
  display: flex;
  gap: 5px;
}
